import { Animator } from "./modules/animator.min.js";
import { ComparatorManager } from "./modules/comparatorManager.min.js";
import { ComparatorMemory } from "./modules/comparatorMemory.min.js";
import { EventsManager } from "./modules/eventsManager.min.js";
import { FormManager } from "./modules/formManager.min.js";
import { Loading } from "./modules/loading.min.js";
import { LoginForm } from "./modules/loginForm.min.js";
import { LoginRequestForm } from "./modules/loginRequestForm.min.js";
import { MenuManager } from "./modules/menuManager.min.js";
import { Mep } from "./modules/mep.min.js";
import { NewPasswordForm } from "./modules/newPasswordForm.min.js";
import { PasswordResetForm } from "./modules/passwordResetForm.min.js";
import { PjaxNavigation } from "./modules/pjaxNavigation.min.js";
import { ProfileForm } from "./modules/profileForm.min.js";
import { ScrollManager } from "./modules/scrollManager.min.js";
import { SlidersManager } from "./modules/slidersManager.min.js";
import { TabSystem } from "./modules/tabsystemManager.min.js";
import { Workers } from "./modules/workers.min.js";

// Init workers first
window.workers = new Workers();

window.animator = new Animator();
window.comparator = new ComparatorManager();
window.comparator_memory = new ComparatorMemory();
window.evnManager = new EventsManager();
window.forms = new FormManager();
window.loading = new Loading();
window.loginForm = new LoginForm();
window.loginRequestForm = new LoginRequestForm();
window.menu = new MenuManager();
window.mep = new Mep();
window.passwordResetForm = new PasswordResetForm();
window.pjaxnav = new PjaxNavigation();
window.newPasswordForm = new NewPasswordForm();
window.profileForm = new ProfileForm();
window.scrollManager = new ScrollManager();
window.sliders = new SlidersManager();
window.tabsystem = new TabSystem();

function onNavigationDone() {
  window.loginForm = new LoginForm();
  window.loginRequestForm = new LoginRequestForm();
  window.passwordResetForm = new PasswordResetForm();
  window.profileForm = new ProfileForm();

  window.loginForm.init();
  window.loginRequestForm.init();
  window.passwordResetForm.init();
  window.profileForm.init();
}

function domIsReady() {
  window.app = {
    init: function () {
      window.forms.init();
      window.animator.init();
      window.comparator.init();
      window.comparator_memory.init();
      window.evnManager.init();
      window.loginForm.init();
      window.loginRequestForm.init();
      window.menu.init();
      window.mep.init();
      window.passwordResetForm.init();
      window.profileForm.init();
      window.pjaxnav.init(onNavigationDone);
      window.newPasswordForm.init();
      window.scrollManager.init();
      window.sliders.init();
      window.tabsystem.init();
    },
  };

  window.loading.init();
}

// Initialize page
$(window).on("load", function () {
  domIsReady();
});
